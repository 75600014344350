import React, {useState, useEffect} from 'react';
import './Navbar.css';
import logo from '../../images/stova-new-logo.svg'
import LangBtn from '../langButton/LangBtn';
import {Link} from 'react-router-dom';
import NavDropDown from '../navDropDown/NavDropDown';
import {useLocation} from 'react-router-dom'
import Button from '../Button/Button';




function Navbar() {
        const [menuToggle, setMenuToggle] = useState(false);
        const [navDropDownToggle, setNavDropDownToggle] = useState(false);
        const [subMenu, setSubMenu] = useState(false);

        const {pathname} = useLocation()

        
        function handleMenuToggle(){
            setMenuToggle((prev)=> !prev)
            setSubMenu(false)
        }
        
        function handleNavDropDownToggle(e){
            setNavDropDownToggle((prev)=> !prev);

        }
        
        function handleSubMenu(){
            setSubMenu((prev)=> !prev)
        }

        useEffect(()=>{
            setNavDropDownToggle(false)
            setMenuToggle(false)
        }, [pathname])



    return (
        <nav className="navbar__container">
            <div className="navbar">
                <Link to ='/'>
                    <div className="navbar__logo">
                     <img src={logo} alt="Stova logo" />
                    </div>
                </Link>

                <div className="navbar__navlink navbar__desktop">
                    <ul>
                        <li><Link to="/" className="nav-active">Home</Link></li>
                        <li><Link to="/about" className="nav-active">About</Link></li>
                        <li onClick={handleNavDropDownToggle}><Link to="#" className="nav-active">Products </Link></li>
                        <li><Link to="/career" className="nav-active">Career</Link></li>
                        <li><Link to="/contact" className="nav-active">Contact</Link></li>
                        <li><Link to="/media" className="nav-active">Media</Link></li>
                    </ul>
                    <div className="navbar__langbtn">
                        <Button text='Contact us' link='./contact'/>
                    </div>


                </div>

                {!navDropDownToggle === false?(<NavDropDown handleToggle={handleNavDropDownToggle}/>): null}


                <div className="navbar__mobile">
                    <div className="navbar__mobile-btn" onClick={handleMenuToggle}>
                    {!menuToggle?(<div className="navbar__mobile-hamburger">
                            <div className="navbar_hamburger navbar__hamburger-1"></div>
                            <div className="navbar_hamburger navbar__hamburger-2"></div>
                            <div className="navbar_hamburger navbar__hamburger-3"></div>
                        </div>):null}
                        
                        {menuToggle?(<div className="navbar__mobile-hamburger">
                            
                            <div className="navbar_hamburger navbar__hamburger-3"></div>
                        </div>):null}                      

                        {/* <span className="navbar__mobile-text" >{!menuToggle?'Menu':'Close'}</span> */}
                    </div>

                    {menuToggle?(   <div className="navbar__mobile-container">
                        <div className="navbar__mobile-overlay">    
                        </div>

                        <div className="navbar__mobile-nav">
                            {subMenu === false?(<div className="navbar__mobile-menu navbar__mobile-primary-menu">
                                <ul>
                                    <li><Link to="/" className="nav-active">Home</Link></li>
                                    <li><Link to="/about"> About</Link></li>
                                    <li><Link to="#" onClick={handleSubMenu}> Products <span className="navbar-count">3</span></Link></li>
                                    <li><Link to="career"> Career</Link></li>
                                    <li><Link to="/contact"> Contact</Link></li>
                                    <li><Link to="/media"> Media</Link></li>
                                </ul>
                            </div>):null}

                            {subMenu === true?(<div className="navbar__mobile-menu navbar__mobile-sub-menu">
                                <div className="navbar_-mobile-back" onClick={handleSubMenu}>
                                <svg width="8" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.707 0L0 5.707l5.707 5.707L7.121 10 2.828 5.707l4.293-4.293L5.707 0z" fill="#000"/></svg>
                                </div>
                                <ul>
                                    <li><Link to="/home-care-products">Home Care Products</Link></li>
                                    <li><Link to="/body-care-products">Beauty & Body Care</Link></li>
                                    <li><Link to="/preforms-products">Pet & Preforms</Link></li>
                                </ul>
                            </div>):null}
                            
                            <div className="navbar__langbtn">
                             <LangBtn/>
                            </div>
                        </div> 
                    </div>):null}
                   
                 
                </div>
            </div>
        </nav>



    )
}

export default Navbar
