import React from 'react';
import './Loading.css'

function Laoding() {
    return (
         <div className="loader__container">
             <div className="loader">

            </div>

         </div>
    )
}

export default Laoding
