import React from 'react';
import './ScentFeatures.css'

function ScentFeatures() {
    return (
        <div className="scentfeatures__container">

            <div className="scentsfeatures">
                <div className="scentsfeature">
                    <h3>Variety of scents</h3>
                    <p>Whether you are headed to work, meeting with friends, party, these sleek cans are perfect for all occasions!</p>
                </div>
                <div className="scentsfeature">
                    <h3>Great Scents</h3>
                    <p>A refreshing,  pleasant fragrance sourced from sustainable resources and refined to excellence.</p>
                </div>
                <div className="scentsfeature">
                    <h3>Long lasting scents</h3>
                    <p>Designed for all-day freshness. Our body sprays are formulated to last for a longer duration.</p>
                </div>
            </div>

        </div>
    )
}

export default ScentFeatures
