
 const countries = [

            {"name": "afghanistan", "value": "Afghanistan"},
            {"name": "aland_islands", "value": "Aland Islands"},
            {"name": "albania", "value": "Albania"},
            {"name": "algeria", "value": "Algeria"},
            {"name": "american_samoa", "value": "American Samoa"},
            {"name": "andorra", "value": "Andorra"},
            {"name": "angola", "value": "Angola"},
            {"name": "anguilla", "value": "Anguilla"},
            {"name": "antarctica", "value": "Antarctica"},
            {"name": "antigua_and_barbuda", "value": "Antigua and Barbuda"},
            {"name": "argentina", "value": "Argentina"},
            {"name": "armenia", "value": "Armenia"},
            {"name": "aruba", "value": "Aruba"},
            {"name": "australia", "value": "Australia"},
            {"name": "austria", "value": "Austria"},
            {"name": "azerbaijan", "value": "Azerbaijan"},
            {"name": "bahamas", "value": "Bahamas"},
            {"name": "bahrain", "value": "Bahrain"},
            {"name": "bangladesh", "value": "Bangladesh"},
            {"name": "barbados", "value": "Barbados"},
            {"name": "belarus", "value": "Belarus"},
            {"name": "belgium", "value": "Belgium"},
            {"name": "belize", "value": "Belize"},
            {"name": "benin", "value": "Benin"},
            {"name": "bermuda", "value": "Bermuda"},
            {"name": "bhutan", "value": "Bhutan"},
            {"name": "bolivia", "value": "Bolivia"},
            {"name": "bonaire", "value": "Bonaire"},
            {"name": "bosnia_and_herzegovina", "value": "Bosnia and Herzegovina"},
            {"name": "botswana", "value": "Botswana"},
            {"name": "bouvet_island", "value": "Bouvet Island"},
            {"name": "brazil", "value": "Brazil"},
            {"name": "brunei_darussalam", "value": "Brunei Darussalam"},
            {"name": "bulgaria", "value": "Bulgaria"},
            {"name": "burkina_faso", "value": "Burkina Faso"},
            {"name": "burundi", "value": "Burundi"},
            {"name": "cabo_verde", "value": "Cabo Verde"},
            {"name": "cambodia", "value": "Cambodia"},
            {"name": "cameroon", "value": "Cameroon"},
            {"name": "canada", "value": "Canada"},
            {"name": "cayman_islands", "value": "Cayman Islands"},
            {"name": "central_african_republic", "value": "Central African Republic"},
            {"name": "chad", "value": "Chad"},
            {"name": "chile", "value": "Chile"},
            {"name": "china", "value": "China"},
            {"name": "christmas_island", "value": "Christmas Island"},
            {"name": "cocos_islands", "value": "Cocos Islands"},
            {"name": "colombia", "value": "Colombia"},
            {"name": "comoros", "value": "Comoros"},
            {"name": "congo", "value": "Congo"},
            {"name": "congo", "value": "Congo"},
            {"name": "cook_islands", "value": "Cook Islands"},
            {"name": "costa_rica", "value": "Costa Rica"},
            {"name": "cote_d'ivoire", "value": "Cote d'Ivoire"},
            {"name": "croatia", "value": "Croatia"},
            {"name": "cuba", "value": "Cuba"},
            {"name": "curacao", "value": "Curacao"},
            {"name": "cyprus", "value": "Cyprus"},
            {"name": "czech_republic", "value": "Czech Republic"},
            {"name": "denmark", "value": "Denmark"},
            {"name": "djibouti", "value": "Djibouti"},
            {"name": "dominica", "value": "Dominica"},
            {"name": "dominican_republic", "value": "Dominican Republic"},
            {"name": "ecuador", "value": "Ecuador"},
            {"name": "egypt", "value": "Egypt"},
            {"name": "el_salvador", "value": "El Salvador"},
            {"name": "equatorial_guinea", "value": "Equatorial Guinea"},
            {"name": "eritrea", "value": "Eritrea"},
            {"name": "estonia", "value": "Estonia"},
            {"name": "ethiopia", "value": "Ethiopia"},
            {"name": "falkland_islands", "value": "Falkland Islands"},
            {"name": "faroe_islands", "value": "Faroe Islands"},
            {"name": "fiji", "value": "Fiji"},
            {"name": "finland", "value": "Finland"},
            {"name": "france", "value": "France"},
            {"name": "french_guiana", "value": "French Guiana"},
            {"name": "french_polynesia", "value": "French Polynesia"},
            {"name": "french_southern_territories", "value": "French Southern Territories"},
            {"name": "gabon", "value": "Gabon"},
            {"name": "gambia", "value": "Gambia"},
            {"name": "georgia", "value": "Georgia"},
            {"name": "germany", "value": "Germany"},
            {"name": "ghana", "value": "Ghana"},
            {"name": "gibraltar", "value": "Gibraltar"},
            {"name": "greece", "value": "Greece"},
            {"name": "greenland", "value": "Greenland"},
            {"name": "grenada", "value": "Grenada"},
            {"name": "guadeloupe", "value": "Guadeloupe"},
            {"name": "guam", "value": "Guam"},
            {"name": "guatemala", "value": "Guatemala"},
            {"name": "guernsey", "value": "Guernsey"},
            {"name": "guinea", "value": "Guinea"},
            {"name": "guinea-bissau", "value": "Guinea-Bissau"},
            {"name": "guyana", "value": "Guyana"},
            {"name": "haiti", "value": "Haiti"},
            {"name": "heard_and_mcdonald_islands", "value": "Heard and McDonald Islands"},
            {"name": "holy_see", "value": "Holy See"},
            {"name": "honduras", "value": "Honduras"},
            {"name": "hong_kong", "value": "Hong Kong"},
            {"name": "hungary", "value": "Hungary"},
            {"name": "iceland", "value": "Iceland"},
            {"name": "india", "value": "India"},
            {"name": "indonesia", "value": "Indonesia"},
            {"name": "iran", "value": "Iran"},
            {"name": "iraq", "value": "Iraq"},
            {"name": "ireland", "value": "Ireland"},
            {"name": "isle_of_man", "value": "Isle of Man"},
            {"name": "israel", "value": "Israel"},
            {"name": "italy", "value": "Italy"},
            {"name": "jamaica", "value": "Jamaica"},
            {"name": "japan", "value": "Japan"},
            {"name": "jersey", "value": "Jersey"},
            {"name": "jordan", "value": "Jordan"},
            {"name": "kazakhstan", "value": "Kazakhstan"},
            {"name": "kenya", "value": "Kenya"},
            {"name": "kiribati", "value": "Kiribati"},
            {"name": "korea", "value": "Korea"},
            {"name": "korea", "value": "Korea"},
            {"name": "kuwait", "value": "Kuwait"},
            {"name": "kyrgyzstan", "value": "Kyrgyzstan"},
            {"name": "lao_peoples", "value": "Lao People's"},
            {"name": "latvia", "value": "Latvia"},
            {"name": "lebanon", "value": "Lebanon"},
            {"name": "lesotho", "value": "Lesotho"},
            {"name": "liberia", "value": "Liberia"},
            {"name": "libya", "value": "Libya"},
            {"name": "liechtenstein", "value": "Liechtenstein"},
            {"name": "lithuania", "value": "Lithuania"},
            {"name": "luxembourg", "value": "Luxembourg"},
            {"name": "macao", "value": "Macao"},
            {"name": "macedonia", "value": "Macedonia"},
            {"name": "madagascar", "value": "Madagascar"},
            {"name": "malawi", "value": "Malawi"},
            {"name": "malaysia", "value": "Malaysia"},
            {"name": "maldives", "value": "Maldives"},
            {"name": "mali", "value": "Mali"},
            {"name": "malta", "value": "Malta"},
            {"name": "marshall_islands", "value": "Marshall Islands"},
            {"name": "martinique", "value": "Martinique"},
            {"name": "mauritania", "value": "Mauritania"},
            {"name": "mauritius", "value": "Mauritius"},
            {"name": "mayotte", "value": "Mayotte"},
            {"name": "mexico", "value": "Mexico"},
            {"name": "micronesia", "value": "Micronesia"},
            {"name": "moldova", "value": "Moldova"},
            {"name": "monaco", "value": "Monaco"},
            {"name": "mongolia", "value": "Mongolia"},
            {"name": "montenegro", "value": "Montenegro"},
            {"name": "montserrat", "value": "Montserrat"},
            {"name": "morocco", "value": "Morocco"},
            {"name": "mozambique", "value": "Mozambique"},
            {"name": "myanmar", "value": "Myanmar"},
            {"name": "namibia", "value": "Namibia"},
            {"name": "nauru", "value": "Nauru"},
            {"name": "nepal", "value": "Nepal"},
            {"name": "netherlands", "value": "Netherlands"},
            {"name": "new_caledonia", "value": "New Caledonia"},
            {"name": "new_zealand", "value": "New Zealand"},
            {"name": "nicaragua", "value": "Nicaragua"},
            {"name": "niger", "value": "Niger"},
            {"name": "nigeria", "value": "Nigeria"},
            {"name": "niue", "value": "Niue"},
            {"name": "norfolk_island", "value": "Norfolk Island"},
            {"name": "northern_mariana_islands", "value": "Northern Mariana Islands"},
            {"name": "norway", "value": "Norway"},
            {"name": "oman", "value": "Oman"},
            {"name": "pakistan", "value": "Pakistan"},
            {"name": "palau", "value": "Palau"},
            {"name": "palestine", "value": "Palestine"},
            {"name": "panama", "value": "Panama"},
            {"name": "papua_new_guinea", "value": "Papua New Guinea"},
            {"name": "paraguay", "value": "Paraguay"},
            {"name": "peru", "value": "Peru"},
            {"name": "philippines", "value": "Philippines"},
            {"name": "pitcairn", "value": "Pitcairn"},
            {"name": "poland", "value": "Poland"},
            {"name": "portugal", "value": "Portugal"},
            {"name": "puerto_rico", "value": "Puerto Rico"},
            {"name": "qatar", "value": "Qatar"},
            {"name": "reunion", "value": "Reunion"},
            {"name": "romania", "value": "Romania"},
            {"name": "russian_federation", "value": "Russian Federation"},
            {"name": "rwanda", "value": "Rwanda"},
            {"name": "saint_barthelemy", "value": "Saint Barthelemy"},
            {"name": "saint_helena", "value": "Saint Helena"},
            {"name": "saint_kitts_and_nevis", "value": "Saint Kitts and Nevis"},
            {"name": "saint_lucia", "value": "Saint Lucia"},
            {"name": "saint_martin", "value": "Saint Martin"},
            {"name": "saint_pierre_and_miquelon", "value": "Saint Pierre and Miquelon"},
            {"name": "saint_vincent_and_the_grenadines", "value": "Saint Vincent and the Grenadines"},
            {"name": "samoa", "value": "Samoa"},
            {"name": "san_marino", "value": "San Marino"},
            {"name": "sao_tome_and_principe", "value": "Sao Tome and Principe"},
            {"name": "saudi_arabia", "value": "Saudi Arabia"},
            {"name": "senegal", "value": "Senegal"},
            {"name": "serbia", "value": "Serbia"},
            {"name": "seychelles", "value": "Seychelles"},
            {"name": "sierra_leone", "value": "Sierra Leone"},
            {"name": "singapore", "value": "Singapore"},
            {"name": "sint_maarten", "value": "Sint Maarten"},
            {"name": "slovakia", "value": "Slovakia"},
            {"name": "slovenia", "value": "Slovenia"},
            {"name": "solomon_islands", "value": "Solomon Islands"},
            {"name": "somalia", "value": "Somalia"},
            {"name": "south_africa", "value": "South Africa"},
            {"name": "south_georgia", "value": "South Georgia"},
            {"name": "south_sudan", "value": "South Sudan"},
            {"name": "spain", "value": "Spain"},
            {"name": "sri_lanka", "value": "Sri Lanka"},
            {"name": "sudan", "value": "Sudan"},
            {"name": "suriname", "value": "Suriname"},
            {"name": "svalbard_and_jan mayen", "value": "Svalbard and Jan Mayen"},
            {"name": "swaziland", "value": "Swaziland"},
            {"name": "sweden", "value": "Sweden"},
            {"name": "switzerland", "value": "Switzerland"},
            {"name": "syrian_arab_republic", "value": "Syrian Arab Republic"},
            {"name": "taiwan", "value": "Taiwan"},
            {"name": "tajikistan", "value": "Tajikistan"},
            {"name": "tanzania", "value": "Tanzania"},
            {"name": "thailand", "value": "Thailand"},
            {"name": "timor_leste", "value": "Timor Leste"},
            {"name": "togo", "value": "Togo"},
            {"name": "tokelau", "value": "Tokelau"},
            {"name": "tonga", "value": "Tonga"},
            {"name": "trinidad_and_tobago", "value": "Trinidad and Tobago"},
            {"name": "tunisia", "value": "Tunisia"},
            {"name": "turname", "value": "Turname"},
            {"name": "turkmenistan", "value": "Turkmenistan"},
            {"name": "turks_and_caicos islands", "value": "Turks and Caicos Islands"},
            {"name": "tuvalu", "value": "Tuvalu"},
            {"name": "uganda", "value": "Uganda"},
            {"name": "ukraine", "value": "Ukraine"},
            {"name": "united_arab_emirates", "value": "United Arab Emirates"},
            {"name": "united_kingdom", "value": "United Kingdom"},
            {"name": "united_states_of america", "value": "United States of America"},
            {"name": "uruguay", "value": "Uruguay"},
            {"name": "uzbekistan", "value": "Uzbekistan"},
            {"name": "vanuatu", "value": "Vanuatu"},
            {"name": "venezuela", "value": "Venezuela"},
            {"name": "viet_nam", "value": "Viet Nam"},
            {"name": "virgin_islands", "value": "Virgin Islands"},
            {"name": "virgin_islands", "value": "Virgin Islands"},
            {"name": "wallis_and_futuna", "value": "Wallis and Futuna"},
            {"name": "western_sahara", "value": "Western Sahara"},
            {"name": "yemen", "value": "Yemen"},
            {"name": "zambia", "value": "Zambia"},
            {"name": "zimbabwe", "value": "Zimbabwe"}
        ];

export default countries;