import React from 'react';
import {useParams, useHistory} from "react-router-dom";
import './Job.css';

import jobs from '../../data/jobs';
import {motion} from 'framer-motion';
import { pageVariants } from '../../utils/animateVariant';



function Job() {

    const {id} = useParams();
    const history = useHistory();

  return (
   
    <motion.div  initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}>
    <div className="stova__job_page">
        <div className="stova__job-back">
            <svg onClick={history.goBack} width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="28.5" cy="28.5" r="28.5" fill="#109F38"/>
                <path d="M33.2857 21.5714L26.8571 28L33.2857 34.4286L32 37L23 28L32 19L33.2857 21.5714Z" fill="white"/>
            </svg>
        </div>
        <div className="stova_job-details">
            <div className="stova_jobpage-title">
                <h2>{jobs[id].title}</h2>
                <p>{jobs[id].desc}</p>
            </div>

            {jobs[id].responsibilities.length >1 && <div className="stova_jobpage-resp">
                <h3>{`${jobs[id].title}'s Responsibilities:`}</h3>
                <ul>
                    {jobs[id].responsibilities.map((cur, i)=>{
                        return <li key={i}>{cur}</li>
                    })}
                    
                </ul>
            </div>}
            <div className="stova_jobpage-req">
                <h3> Requirements:</h3>
                <ul>
                {jobs[id].Requirements.map((cur, i)=>{
                        return <li key={i}>{cur}</li>
                    })}
                    
                </ul>
            </div>
            <div className="stova_jobpage-salary">
                <h3>Salary</h3>
                <p>Very attractive</p>
            </div>
            <div className="stova_jobpage-apply">
                <h3>Application Close</h3>
                <p>Not specified.</p>
            </div>
        </div>

    </div>
    </motion.div>
  
  )
}

export default Job