import React from 'react';
import ProductSnippet from '../../components/productSnippet/ProductSnippet';
import Whyus from '../../components/whyus/Whyus';
import './Homecare.css';

import productData from '../../data/products';
import Cta from '../../components/cta/Cta';
import {motion} from 'framer-motion';
import { pageVariants } from '../../utils/animateVariant';


function Homecare() {
    
    return (
        <motion.div  initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants} >
        <div className="homecarepage__container">
            <div className="homecarepage__header">
                <div className="homecarepage__header-content">
                    <h1>Home Care Products</h1>
                    <p>We offer a wide range of Air Fresheners, carefully nurtured to help maintain a good atmospheric environment. We use the best ingredients to ensure our products are of good quality and great standards.</p>
                </div>
                <div className="homecarepage__header-image"><div className="img"></div></div>
            </div>
        </div>
        <Whyus/>

            {productData.map((product, key)=>{
                return <ProductSnippet key={key} product ={product}/>
            })}

            <Cta/>

        </motion.div>
    )
}

export default Homecare
