import React from 'react';
import './CoreValues.css'

function CoreValues() {
    return (
        <div className="corevalues__container">

            <div className="corevalues">
                <h2>Our core values</h2>
                <div className="corevalues__cards">
                    <div className="corevalues__card">
                        <h3>Quality </h3>
                        <p>We take pride in providing high value products and services that we stand behind, which ensures customer satisfaction, profitability and the future of our employees and our growth.</p>
                    </div>
                    <div className="corevalues__card">
                        <h3>Customer Satisfaction</h3>
                        <p>We strive to provide the best customer service. One way we do this is with flexible scheduling, quality products, efficient services, and innovative solutions that result in a good value for the customer and company.</p>
                    </div>
                    <div className="corevalues__card">
                        <h3>Innovation </h3>
                        <p>We foster a work environment where creative thinking is encouraged and rewarded in order to create opportunities for process improvement and more cost-effective sustainable products and services.</p>
                    </div>
                    <div className="corevalues__card">
                        <h3>Safety </h3>
                        <p>We strive to provide a work environment that reduces risk to our employees and traveling public by planning teamwork and using the proper protection equipment, tools and procedures.</p>
                    </div>
                    <div className="corevalues__card">
                        <h3>Integrity </h3>
                        <p>We demonstrate and encourage trust, honesty, and courageous behavior in everything we do. We strive to be consistent in our day-to-day interactions and decisions, being accountable for any mistake that occur.</p>
                    </div>
                    <div className="corevalues__card">
                        <h3>Teamwork </h3>
                        <p>We believe that collaboration is the key to successfully tackling any problem. This also extends to our clients; we approach every business relationship with the belief that it is a partnership.</p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CoreValues
