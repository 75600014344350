import dishwasher from '../images/swiss-flower-dish.jpg'
import liquid from '../images/Swiss Liquid-update-1.png'
import gel from '../images/swiss-flower-gel.jpg'
import regular from '../images/swiss-flower-regular.jpg'
import dangler from '../images/swiss-flower-dangler.jpg'
import pad from '../images/swiss-flower-pad.jpg'

//Dish
import dishMulti from '../images/swiss-dish-washer-multi.jpg'
import dishLemon from '../images/swiss-dish-washer-lemon.jpg'


//Liquid
import tangerine from '../images/Swiss-Tangerine.jpg'
import coolfresh from '../images/Swiss-Lemon.jpg'
import freshair from '../images/Swiss-Fresh-Air.jpg'
import gardenflower from '../images/Swiss-Garden-Flower.jpg'
import springflower from '../images/Swiss-Spring-Flower.jpg'
import summercent from '../images/Swiss-Summer-Scent.jpg'
import sweetpine from '../images/Swiss-Sweet-Pine.jpg'


//Gel
import gelCampestra from '../images/gel-campestra-1.jpg'
import gelFlower from '../images/gel-flower-fresh-1.jpg'
import gelLavenda from '../images/gel-lavenda-1.jpg'
import gelLemon from '../images/gel-lemon-1.jpg'
import gelMultiFruity from '../images/gel-multi-fruity-1.jpg'
import gelSweetFlorale from '../images/gel-sweet-florale-1.jpg'
import gelTropicana from '../images/gel-tropicana-1.jpg'
import gelVanilla from '../images/gel-vanilla-1.jpg'


//Regular
import campestra from '../images/regular-campestra.jpg'
import florale from '../images/regular-florale.jpg'
import fruity from '../images/regular-fruity.jpg'
import lavenda from '../images/regular-lavenda.jpg'
import vanilla from '../images/regular-vanilla.jpg'



//dangler
import brandedGrey from '../images/Branded-grey.png'
import brandedLemon from '../images/Branded-lemon.png'
import brandedOrangeRed from '../images/Branded-orange-red.png'
import brandedOrange from '../images/Branded-orange.png'
import brandedPink from '../images/Branded-pink.png'
import brandedRed from '../images/Branded-red.png'



//Pad
// import scentpadBlossom from '../images/Scentpad-blossom.jpg'
import scentpadFreshDew from '../images/Scentpad-fresh-dew.jpg'
import scentpadMarine from '../images/Scentpad-marine.jpg'
import scentpadOrient from '../images/Scentpad-orient.jpg'






 const productData =[
    {
        name:'Dish Washing Liquid.',
        desc:'Our Swiss Flower Dish Washing Liquid is meticulously crafted to provide you with the best cleaning performance while delivering a fragrant burst of Swiss flower freshness.  It comes in different sizes. 750ml, 450ml & 200ml.',
        image: dishwasher,
        buyUrl: 'https://www.konga.com/search?search=swiss%20flower',
        position: 'left',
        id: '0',
        idname:'dish',
        productImages: [
            {image:dishMulti, name:'Swiss FLower Dish Washing Liquid, Multi-Fruity. Available in 750ml, 450ml & 200ml'},
            {image:dishLemon, name:'Swiss FLower Dish Washing Liquid, Lemon. Available in 750ml, 450ml & 200ml'},

            
        ]
      

    },
    {
        name:'Liquid Air Freshener.',
        desc:'Swiss Flower Liquid Air Freshener comes in sweet fragrances that make your surroundings feel more inviting. Swiss Flower Liquid Air Fresheners are suitable for Hotels, Hospitals, Banks, Airplanes, Automobiles, Ships, and other habitable places. It comes in various fragrances, Tangerine, Cool Fresh, Summer Scent, Sweet Pine, Spring Flower, Fresh Air, and Garden Flower.',
        image: liquid,
        buyUrl: 'https://www.konga.com/search?search=swiss%20flower',
        position: 'right',
        id: '1',
        idname:'liquid',
        productImages: [
            {image:tangerine, name:'Swiss FLower Tangerine'},
            {image:coolfresh, name:'Swiss Flower Cool Fresh'},
            {image:freshair, name: 'Swiss Flower Fresh Air'}, 
            {image:gardenflower, name: 'Swiss Flower Garden Flower'},
            {image:springflower, name: 'Swiss FLower Spring Flower'},
            {image:summercent, name:'Swiss FLower Summer Scent'},
            {image:sweetpine, name:'Swiss FLower Sweet Pine'}
            
        ]
      

    },
    {
        name:'Gel Air Freshener.',
        desc:'Swiss Flower Gel Air Freshener is known for dispensing sweet scents in the air. It is carefully formulated with quality ingredients which helps in keeping your surroundings smelling fresh and clean.',
        image: gel,
        buyUrl: 'https://www.konga.com/search?search=swiss%20flower',
        position: 'left',
        id: '2',
        idname:'gel',
        productImages: [
            {image:gelCampestra, name:'Swiss Flower Gel Campestra'},
            {image:gelFlower, name: 'Swiss Flower Gel Flower Fresh'}, 
            {image:gelLavenda, name: 'Swiss Flower Gel Lavenda'},
            {image:gelLemon, name: 'Swiss FLower Gel Lemon'},
            {image:gelMultiFruity , name:'Swiss FLower Gel Multi-Fruity'},
            {image:gelSweetFlorale, name:'Swiss FLower Gel Sweet Florale'},
            {image:gelTropicana, name:'Swiss FLower Gel Tropicana'},
            {image:gelVanilla, name:'Swiss FLower Gel Vanilla'},
        ]
    },
    {
        name:'Regular & Refil Air Freshener.',
        desc:'Swiss Flower Refill & Regular Air Freshener is well saturated with fragrance to purify the air, eliminate bad odour and emit natural scents. It comes in a well-designed refillable case for easy usage.',
        image: regular,
        buyUrl: 'https://www.konga.com/search?search=swiss%20flower',
        position: 'right',
        id: '3',
        idname:'regular',
        productImages: [
            {image:campestra, name:'Swiss Flower Regular Campestra'},
            {image:florale, name: 'Swiss Flower Regular Florale'}, 
            {image:fruity, name: 'Swiss Flower Regular Multi-Fruity'},
            {image:lavenda, name: 'Swiss FLower Regular Lavenda'},
            {image:vanilla, name:'Swiss FLower Regular Vanilla'},

        ]
     

    },
    {
        name:'Car Dangler Air freshener.',
        desc:'Swiss Flower Branded Car Dangling Air Freshener comes in superior quality fragrance in amazing colours. Renowned as “The King of Car freshness”, keeps your car fresh every minute and moment. ',
        image: dangler,
        buyUrl: 'https://www.konga.com/search?search=swiss%20flower',
        position: 'left',
        id: '4',
        idname:'dangler',
        productImages: [
            {image:brandedGrey, name:''},
            {image:brandedLemon, name: ''}, 
            {image:brandedOrangeRed, name: ''},
            {image:brandedOrange, name: ''},
            {image:brandedRed, name:''},
            {image:brandedPink, name:''},

        ]

    },
    {
        name:'Scent Pad Air freshener.',
        desc:'A reliable odour repellent. It comes in a variety of fragrances, keeping your environment fresh with long-lasting fragrances. It comes in 3 fragrances, Orient, Marine, and Fresh Dew.',
        image: pad,
        buyUrl: 'https://www.konga.com/search?search=swiss%20flower',
        position: 'right',
        id: '5',
        idname:'pad',
        productImages: [
            // {image:scentpadBlossom, name:'Swiss Flower Scent Pad Blossom'},
            {image:scentpadFreshDew, name: 'Swiss Flower Scent Pad Fresh Dew'}, 
            {image:scentpadMarine, name: 'Swiss Flower Scent Pad Marine'},
            {image:scentpadOrient, name: 'Swiss Flower Scent Pad Orient'},

        ]

    },

]

export default productData;