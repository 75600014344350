import React, {useState} from 'react';
import './ContactForm.css';
import countries from '../../utils/countries';

import emailjs from 'emailjs-com';
import Laoding from '../loading/Laoding';

import contactSchema from '../../utils/formValidation'
import FormResponse from '../formResponse/FormResponse';



function ContactForm() {

        const [formData, setFormData] =  useState(
            {
            name: '',
            email: '',
            location:'Nigeria',
            message: ''
        })

        const [personName, setPersonName] = useState('')

        const [isLoading, setIsLoading] = useState(false)

        const [formError, setFormError] = useState(false)
        
        const [sendAnotherMail, setSendAnotherMail] = useState(true)




        //HANDLE FORM CHANGE
        function handleChange(e){
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            })
        }


        const capitalize = (s) => {
            if (typeof s !== 'string') return ''
            return s.charAt(0).toUpperCase() + s.slice(1)
        }


        //FORM VALIDATION
        async function formValidate(){
           const check = await contactSchema.isValid({...formData})
        //    console.log('heloo from check' + check)
           
           if(check){
               setFormError(false)
            }else if(!check){
                setFormError(true)
            }

            return check
         }
       
        //SEND EMAIL
       async function sendEmail(e) {
                setIsLoading(true);
                try {
                    // const mail = await  emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, e.target, process.env.REACT_APP_USER_ID);
                    await  emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, e.target, process.env.REACT_APP_USER_ID);
                    setPersonName(formData.name)
                    // console.log(mail);
                    setIsLoading(false);
                    setFormData({
                        name: '',
                        email: '',
                        location:'Nigeria',
                        message: ''
                        })
                        setSendAnotherMail(false)
                    
                } catch (error) {  
                    // console.log(error.text);
                    setIsLoading(false);
                }
        }

        //HANDLE FORM SUBMIT
        async function handleSubmit(e){
            e.preventDefault();

            const check = await formValidate();

            // console.log('from submit' + check)

            if(check){
              return  await sendEmail(e)
            }

            if(!check){
                setFormError(true)
                // console.log('I am the last one' + formError)
            }
        }

        //HANDLE SEND ANOTHER MAIL
        function handleAnotherMessage(){
            setSendAnotherMail(true)
        }


    return (
        <div className="contactform__container">

            {isLoading?<Laoding/>:null}
            {formError?<h2 className='formerror'>Check your entries</h2> : null}

            {sendAnotherMail? (
                <form onSubmit={handleSubmit} className="contactform">
                <div className="contactform_input">
                    <label htmlFor="name">Name</label>
                    <input type="text" placeholder="Enter your full name" id="name" name="name"  value={formData.name} onChange={handleChange}/>
                </div>
                <div className="contactform_input">
                    <label htmlFor="email">Email address</label>
                    <input type="email" placeholder="Enter your valid email address" id="email" name="email"  value={formData.email} onChange={handleChange}/>

                </div>
                <div className="contactform_input">
                    <label htmlFor="location">Location</label>
                    <select id="location" name="location" value={formData.location} onChange={handleChange}>
                        {countries.map((country, i)=>{
                            return  <option key ={i} value={country.value}> { capitalize(country.name)}</option>
                        })}
                    </select>
                </div>

                <div className="contactform_input">
                    <label htmlFor="message">Message</label>
                    <textarea type="text" placeholder="Write your message" id="message" name="message"  value={formData.message} onChange={handleChange}/>
                </div>
                <div className="contactform_input">
                    <button>Send message</button>
                </div>
            </form>
            ):null}

            {!sendAnotherMail?(
                <FormResponse name={personName} handleAnotherMessage={handleAnotherMessage} />

            ): null}
            
            


            
        </div>
    )
}

export default ContactForm
