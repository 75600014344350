

// eslint-disable-next-line no-unused-vars
const jobs =[
    {
        title:"Marketing & Sales Executive",
        desc: "We are looking to hire an experienced sales and marketing executive to help drive company sales. In this position, you will be involved in field sales with the company’s branded mini bus, developing sales strategies, maintaining customer relations, and creating sales reports.",
        responsibilities: ["Selling of company’s products",
        "Contributing to the development of marketing strategies.",
        "Conducting market research on rival products.",
        "Designing and implementing marketing plans for company products.",
        "Working with the sales team to develop targeted sales strategies.",
        "Answering client queries about product specifications and uses.",
        "Maintaining customer/sub distributor’s relations.",
        "Tracking sales data to ensure the company meets sales quotas/targets",
        "Creating and presenting sales performance reports."],

        Requirements:["Bachelor’s degree in marketing, business, or related field.",
        "Must reside in the Ibeju-Lekki axis of Lagos",
        "Proven work experience as a sales and marketing executive.",
        "Knowledge of modern marketing techniques.",
        "High-level communication and networking skills.",
        "A passion for sales good knowledge of Lagos roads.",
        "Must possess a valid driver’s license.",
        "Excellent interpersonal and persuasive skills.",
        "Ability to work well under pressure."
        ],

        salary: "Very attractive.",

        Application_close: "Not specified."
        
    },
    {
        title:"Senior Accounts Officer",
        desc: "We are hiring a Senior Accounts Officer who will be responsible for ensuring the smooth running of finance or an accounting department. You need to combine your leadership responsibilities with day-to-day accounting duties, such as data input and reporting.",
        responsibilities: ["Assisting with accounting tasks such as invoicing or tracking purchase order numbers.",
        "Creating staff payrolls and payment of client or supplier payments.",
        "Investigating accounting irregularities, mistakes or potential fraud.",
        "Producing regular financial reports, such as budgets or cash flow forecasts.",
        "Offering advice and suggestions to management that can improve the accounting processes and financial stability of a company.",
        "Reconciling balance sheets.",
        "Ensuring compliance with legal accounting standards.",],

        Requirements:["B SC or HND in Accounting or any relevant discipline.",
        "Professional certification will be an added advantage.",
        "5 years of experience."
        ],

        salary: "Very attractive.",

        Application_close: "Not specified."
        
    },
    {
        title:"Power Engineer",
        desc: "Power Engineers are responsible for the day-to-day operations of power plants/houses. They ensure that all systems are functioning properly and monitor performance to ensure that they’re running at maximum efficiency. Power plant engineers may also be tasked with overseeing any upgrades or repairs that need to be made to their facility. This might include anything from replacing outdated equipment to installing new safety features to improve overall performance.",
        responsibilities: ["Selling of company’s products",
        "Contributing to the development of marketing strategies.",
        "Conducting market research on rival products.",
        "Designing and implementing marketing plans for company products.",
        "Working with the sales team to develop targeted sales strategies.",
        "Answering client queries about product specifications and uses.",
        "Maintaining customer/sub distributor’s relations.",
        "Tracking sales data to ensure the company meets sales quotas/targets",
        "Creating and presenting sales performance reports."],

        Requirements:["Experienced in Gas and Diesel engines.",
        "Monitoring plant operations to ensure compliance with safety regulations.",
        "Evaluating designs for new power plants and making recommendations on improvements.",
        "Working knowledge of diesel and gas engines.",
        "Working knowledge of Synchronization Panels and  Control.",
        "Preparing operating instructions and manuals for plant staff, including operating procedure protocols and safety procedures.",
        "Performing periodic inspections of equipment to ensure that it is functioning properly.",
        "Operating computer controls to monitor transmission lines, generators, and other equipment.",
        "Installing and repairing equipment gas and diesel generators, transformers, pulleys, belts, pipes, valves, and pumps.",
        "Should be able to collate and analyze data.",
        "Possess strong analytic skills.",
        "Troubleshooting and Reporting skills."
        ],

        salary: "Very attractive.",

        Application_close: "Not specified."
        
    },
    {
        title:"Account Officer",
        desc: ".We are looking for a detail-oriented Account Officer to assist in our financial department. The responsibilities of an Account Officer include keeping financial records up-to-date, checking for inaccuracies in invoices, and handling queries on general accounts. Have knowledge of basic accounting procedures, be open to learning, and have strong communication skills. Ultimately, a quality Account Officer should be able to achieve excellent customer service and maintain accurate financial records.",
        responsibilities: ["Maintaining financial records.",
        "Handling accounts payable and receivable.",
        "Checking invoices.",
        "Resolving accounts to the general ledger.",
        "Contacting clients about transactions and invoices.",
        "Handling queries related to accounts.",
        "Any other duties that may be assigned to you from time to time."],

        Requirements:["B.sc or HND Accounting.",
        "Proven work experience as an Account Officer.",
        "Able to work well within a team.",
        "Proficiency in MS Excel and other accounting packages.",
        "Solid analytical skills.",
        "Detail-oriented.",
        "Strong interpersonal skills.",
        "Experience in using or a desire to learn the required software.",
        "Knowledge of applicable codes related to electronic engineering.",
        "Proficient in design and calculation software where applicable.",
        "Professional certification will be an added advantage.",
        "2 years of experience.",
        ],

        salary: "Very attractive.",

        Application_close: "Not specified."
        
    },
    {
        title:"Truck Driver",
        desc: " ",
        responsibilities: [],

        Requirements:["Ability to drive various tonnages of trucks ranging from 15-30 tons",
        "Good knowledge of Roads (Eastern & Northern) Nigeria.",
        "Good maintenance knowledge of Mercedes trucks.",
        "Age, between 38-50yrs",
        "Valid Driver's license.",
        "At least, 7 years of experience."
        ],

        salary: "Very attractive.",

        Application_close: "Not specified."
        
    },
]

export default jobs