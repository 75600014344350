import React from 'react';
import './Pet.css'
import {motion} from 'framer-motion';
import { pageVariants } from '../../utils/animateVariant';

// import pet from '../../images/pet-preforms-page.jpg'
import petPreforms from '../../images/pet-preforms-one.jpg'
import closures from '../../images/pet-covers.jpg'
import ContactCta from '../../components/contactCta/ContactCta';

function Pet() {
    return (
        <motion.div  initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants} >
        <div className="pet__container">
            <div className="pet__header">
                <div className="pet__header-content">
                    <h1>PET Preforms & Closures</h1>
                    <p>We manufacture industrial products such as pet preforms and closures. These products are used in diverse industries such as food and beverage packaging, automotive, and healthcare.</p>
                </div>
                <div className="pet__header-image"><div className="img"></div></div>
            </div>
        </div>

        <div className="petproducts__container">

            <div className="petproducts__text">
                <h2>Our Products </h2>
                <p>Our PET Preforms and closures are  designed with the latest in PET technology to bring you a fast, efficient and dependable service. We strive for excellence, with our well trained and dedicated staff combined with our sophisticated machines. We ensure the best quality that meets all standards.</p>
            </div>

            <div className="petproducts">
                <div className="petproduct">
                    <div className="petproduct__image">
                        <img src={petPreforms} alt="Pet" />
                    </div>
                    <div className="petproduct__text">
                        <h3>PET Preforms</h3>
                        <p>We manufacture a wide range of high-quality PET preforms to serve various industries from food and beverage, to pharmaceuticals and veterinary medicine.</p>
                    </div>
                </div>
              
                <div className="petproduct">
                    <div className="petproduct__image">
                        <img src={closures} alt="Closures" />

                    </div>
                    <div className="petproduct__text">
                        <h3>Closures</h3>
                        <p>We design and produce a wide range of closures varying from different plastics, lengths, wall thicknesses, and colors to meet the specific needs of our customers. Our products are used in various industries including food and beverage, pharmaceutical, adhesive laminating, and cosmetics.</p>
                    </div>
                </div>
                
            </div>
        </div>

        <ContactCta/>

        </motion.div>
    )
}

export default Pet
