import React from 'react';
import ProductPreview from './ProductPreview';
import './ProductsPreview.css';
import Glamour from '../../images/Deo-Glamour.jpg';
import Magnetic from '../../images/Deo-Magnetic.jpg';
import Oriental from '../../images/Deo-Oriental.jpg';
import Dream from '../../images/Deo-Dream.jpg';
import Attraction from '../../images/Deo-Attraction.jpg';
import Boss from '../../images/Deo-Boss.jpg';
import Fame from '../../images/Deo-Fame.jpg';
// import Princess from '../../images/Deo-Princess.jpg';
import Voyage from '../../images/Deo-voyage.jpg';
import NightAngel from '../../images/Deo-Night.jpg';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';  

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

const products =[
    {
        image:Glamour,
        title:'Glamour'
    },

    {
        image: Magnetic,
        title:'Magnetic',
    },

    {
        image: Oriental,
        title:'Oriental'
    },

    {
        image: NightAngel,
        title: 'Night Angel',
    },

    {
        image: Dream,
        title: 'Dream',
    },
    {
        image: Attraction,
        title: 'Attraction'
    },
    {
        image:Boss,
        title:'Boss'
    },

    {
        image: Fame,
        title:'Fame',
    },

    // {
    //     image: Princess,
    //     title:'Princess'
    // },

    {
        image: Voyage,
        title: 'Voyage',
    },

]

function ProductsPreview() {

    SwiperCore.use([Navigation]);

    return (
        <div className="productPreview__container">
            <div className="p__preview">
                <div className="p__preview--text">
                    <h2>Scent for everyone</h2>
                    <p>Find your match, We got a body spray for you. We have a wide range of body sprays to keep you feeling fresh.</p>
                </div>



                <div className="p__preview-products">
                    {/*  */}
                    <Swiper
                    breakpoints={{
                        // when window width is >= 640px
                        300: {
                          width: 300,
                          slidesPerView: 2.2,
                          navigation :"false"
                        },
                        // when window width is >= 640px
                        640: {
                          width: 640,
                          slidesPerView: 2.5,
                        },
                        // when window width is >= 768px
                        748: {
                          width: 748,
                          slidesPerView: 2,
                        },
                        // when window width is >= 1200px
                        1200: {
                          width: 1200,
                          slidesPerView: 5,
                        },
                        1300: {
                          width: 1300,
                          slidesPerView: 5,
                        },
                      }}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={10}
            slidesPerView={4}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            navigation
            pagination={{ clickable: true }}
            >
                    {products.map((product, i)=>{
                        return <SwiperSlide> <ProductPreview key={i} image={product.image} title={product.title}/></SwiperSlide>
                    })}

                    </Swiper>
                    {/*  */}
                    
                </div>


             
            </div>

        </div>
    )
}

export default ProductsPreview
