import React from 'react';
import './LangBtn.css';
import Uk_flag from '../../images/En-flag.jpg'


function LangBtn() {
    return (
        <div className="lang__btn">
            <img src={Uk_flag} alt="UK Flag" />
            <div className="lang__btn-divider"></div>
            <span className="lang__btn-text">EN</span>
        </div>
    )
}

export default LangBtn
