import React from 'react';
import {Link} from 'react-router-dom';
import './ContactCta.css'

function ContactCta() {
    return (
        <div className="contactcta-container">
            <div className="contactcta">
            {/* <svg width="1720" height="374" viewBox="0 0 1720 374" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M228 92C98.4 133.6 31.3333 226.667 14 268L0 374H1720V0C1654 5.33333 1491.2 31.2 1368 92C1214 168 1038 252 816 228C594 204 390 40 228 92Z" fill="#2BB2AD"/>
</svg> */}

                <h1>For complaints or inquiries about our products and services.</h1>
                <Link to='/contact'>
                    <button>Contact us today</button>
                </Link>
            </div>
        </div>
    )
}

export default ContactCta
