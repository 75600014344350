import React from 'react'
import './ProductPage.css'
import productData from '../../data/products'
import { useParams, useHistory  } from "react-router-dom";
import {motion} from 'framer-motion';
import { pageVariants } from '../../utils/animateVariant';

function ProductPage() {
        const {id} = useParams()
        let history = useHistory();
    const product = productData.find((product)=>{
        return product.idname === id
    })
 function goback (){
    history.goBack()
 }

 

    return (
        <motion.div  initial="initial"
    animate="in"
    exit="out"
    variants={pageVariants} className="producpage__container">

        <div className="productPage__backbtn" onClick={goback}>
        <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.57998 15C6.43059 15.0005 6.28298 14.9676 6.148 14.9035C6.01302 14.8395 5.8941 14.7461 5.79998 14.63L0.969981 8.63003C0.822899 8.4511 0.742493 8.22666 0.742493 7.99503C0.742493 7.76341 0.822899 7.53896 0.969981 7.36003L5.96998 1.36003C6.13972 1.15581 6.38363 1.02739 6.64806 1.00301C6.91248 0.978631 7.17576 1.06029 7.37998 1.23003C7.5842 1.39977 7.71262 1.64368 7.737 1.90811C7.76138 2.17253 7.67972 2.43581 7.50998 2.64003L3.03998 8.00003L7.35998 13.36C7.48226 13.5068 7.55994 13.6856 7.58382 13.8751C7.6077 14.0647 7.57678 14.2571 7.49473 14.4296C7.41267 14.6021 7.28291 14.7475 7.1208 14.8486C6.95869 14.9497 6.77101 15.0023 6.57998 15Z" fill="white"/>
</svg>

            <p>Go back</p>
        </div>
            
            <div className="productpage__texts">
                <h1>{product.name}</h1>
                <p>{product.desc}</p>
            </div>
            <div className= {product.productImages.length >2? "productpage__images": "productpage__images productpage__images-small" }>
                {product.productImages.map((productImage, i)=>{
                   return <div className="productpage__image">
                         <img src={productImage.image} alt=" SwissLiquid Airfreshener"/>
                         <p>{productImage.name}</p>
                    </div>
                })}
               
            </div>

        </motion.div>
    )
}

export default ProductPage
