import React from 'react';
import './CareerOffers.css'

function CareerOffers() {
    return (
        <div className="careeroffer__container">
            <div className="careeroffer__cards-container">
                <div className="careeoffer-card">
                    <div className="careeoffer-card-heading">
                        <div className="careeoffer-card-icon careeoffer-card-icon-1"><svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 6v2h22v16H12v2h18v-2h-2V6H4zm4.002 3A4.016 4.016 0 004 13c0 2.199 1.804 4 4.002 4A4.014 4.014 0 0012 13c0-2.197-1.802-4-3.998-4zM14 10v2h5v-2h-5zm7 0v2h3v-2h-3zM8.002 11C9.116 11 10 11.883 10 13c0 1.12-.883 2-1.998 2C6.882 15 6 14.12 6 13c0-1.117.883-2 2.002-2zM14 14v2h10v-2H14zM4 18v8h2v-6h3v6h2v-5.342l2.064 1.092c.585.31 1.288.309 1.872 0v.002l3.53-1.867-.933-1.77-3.531 1.867-3.096-1.634A3.005 3.005 0 009.504 18H4z" fill="#000"/></svg></div>
                        <h3>Traning & Development</h3>
                    </div>
                    <p>We are fully committed to training and skill building programs that aids personal development.</p>
                </div>
                <div className="careeoffer-card">
                    <div className="careeoffer-card-heading">
                        <div className="careeoffer-card-icon careeoffer-card-icon-2"><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 16V8a2 2 0 00-1-1.73l-7-4a2 2 0 00-2 0l-7 4A2 2 0 003 8v8a2 2 0 001 1.73l7 4a2 2 0 002 0l7-4A2 2 0 0021 16z" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M3.27 6.96L12 12.01l8.73-5.05M12 22.08V12" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg></div>
                        <h3>Good Compensations</h3>
                    </div>
                    <p>We offer good compensations, benefits and incentives to motivate higher performance levels.</p>
                </div>
                <div className="careeoffer-card">
                    <div className="careeoffer-card-heading">
                        <div className="careeoffer-card-icon careeoffer-card-icon-3"><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 9h2v2h-2V9zm2-4h-2v2h2V5zm-6 10h2v-2h-2v2zm2-10h-2v2h2V5zm-2 6h2V9h-2v2zM9 5H7v2h2V5zm0 4H7v2h2V9zm5.55 12H13v-3.5h-2V21H5V3h14v8.03c.71.06 1.39.28 2 .6V1H3v22h12.91c-.41-.56-.91-1.24-1.36-2zM7 19h2v-2H7v2zm2-6H7v2h2v-2zm13 3.5c0 2.6-3.5 6.5-3.5 6.5S15 19.1 15 16.5c0-1.9 1.6-3.5 3.5-3.5s3.5 1.6 3.5 3.5zm-2.3.1c0-.6-.6-1.2-1.2-1.2s-1.2.5-1.2 1.2c0 .6.5 1.2 1.2 1.2s1.3-.6 1.2-1.2z" fill="#000"/></svg></div>
                        <h3>Friendly environment </h3>
                    </div>
                    <p>We have good working environments that keep you comfortable and motivated.</p>
                </div>
            </div>
            
        </div>
    )
}

export default CareerOffers
