import React from "react";
import "./Button.css";
import {Link} from 'react-router-dom'

function Button({text, btnStyle, link}) {
  return (
    <>
    <Link to={link}>
      <button className="btn__component">
        <span className="btn__text">{text}</span>
        {/* <span className="btn__icon">
          <svg
            width="14"
            height="10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 4.5A.5.5 0 01.5 4h11.793L9.146.856a.5.5 0 11.708-.708l4 4a.5.5 0 010 .708l-4 4a.5.5 0 11-.708-.708L12.293 5H.5a.5.5 0 01-.5-.5z"
              fill="#000"
            />
          </svg>
        </span> */}
      </button>
      </Link>
    </>
  );
}

export default Button;
