import React , {useEffect} from 'react';
import './Product.css';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function Product({title, image, bgColor, productLink}) {

        const {ref, inView} = useInView({
            threshold: 0.3
        });

        const animation = useAnimation()

        useEffect(()=>{
            if(inView){
                animation.start({
                    scale: 1,
                    opacity: 1,
                    transition:{type:'tween', duration: .5}
                })
            };

            if(!inView){
               animation.start({
                   scale: .93,
                   opacity: 0
               })
            }

        }, [animation, inView])

    

    return (
    <Link to={productLink}>
        <motion.div ref={ref} className={clsx('product__card ', `${bgColor}`  )} animate={animation}>
            <div className="img-container">
              <img src={image} alt="" />
            </div>
            <div className="product__card-title">
                <h3>{title}</h3>
                <svg width="36" height="36" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M35.175 17.587c0 9.713-7.874 17.588-17.588 17.588C7.874 35.175 0 27.3 0 17.587 0 7.874 7.874 0 17.587 0 27.3 0 35.175 7.874 35.175 17.587zm-15.612-4.025l-7.751 8.808a1 1 0 101.501 1.322l7.462-8.48v6.074a1 1 0 002 0v-8.724a1 1 0 00-.968-1h-.043a.997.997 0 00-.74.34l-1.461 1.66zm2.201-2h-7.666a1 1 0 100 2h5.465" fill="#00000080"/></svg>     
            </div>
         </motion.div>
       
    </Link>
    )
}

export default Product
//<div  className="product__card ">