import React from 'react'
import {Link} from 'react-router-dom';
import FadeIn from 'react-fade-in';




import './NavDropDown.css'

function NavDropDown({handleToggle}) {
    
    return (
       <div className="navbardropdown__container">
            <ul>
                <FadeIn>
                    <li class="nav__border"><Link to="/home-care-products" onClick={handleToggle}><span className="navdropdown__icon navdropdown__icon-1"><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 19V9.799l-7-5.522-7 5.522V19h14zm2 1a1 1 0 01-1 1H4a1 1 0 01-1-1V9.314a1 1 0 01.38-.785l8-6.311a1 1 0 011.24 0l8 6.31a1 1 0 01.38.786V20zM7 12h2a3 3 0 006 0h2a5 5 0 01-10 0z" fill="#000"/></svg></span>   Home Care Products</Link></li>
                    <li class="nav__border"><Link to="/body-care-products" onClick={handleToggle}> <span className="navdropdown__icon navdropdown__icon-2"><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0)"><path d="M18.773 3L18 3.352c-2.385 1.051-5.83 1.447-8.766 2.484-1.468.519-2.827 1.21-3.843 2.32-1.017 1.11-1.641 2.64-1.641 4.594 0 2.021 1.19 3.407 2.297 4.195.411.294.851.545 1.313.75-.464.858-.807 1.633-1.032 2.297-.41 1.198-.474 2.136-.445 2.813.03.677.117 1.14.117 1.125l1.5.14c.047-.542-.094-.826-.117-1.336-.023-.51.02-1.21.375-2.25.709-2.077 2.674-5.496 7.781-10.969l-1.078-1.03c-3.062 3.28-5.039 5.838-6.328 7.897-.431-.169-.84-.389-1.219-.655-.87-.618-1.664-1.524-1.664-2.977 0-1.652.472-2.742 1.242-3.586.771-.844 1.893-1.447 3.235-1.922 2.428-.858 5.434-1.292 8.015-2.25.24.911 1.008 4.031 1.008 8.906 0 2.766-.647 4.351-1.406 5.227-.76.876-1.673 1.125-2.485 1.125-.809 0-1.681-.413-2.438-.938-.755-.524-1.277-1.072-1.804-1.406l-.797 1.266c.136.085.883.776 1.758 1.383.876.606 1.987 1.195 3.281 1.195 1.155 0 2.566-.407 3.633-1.641 1.066-1.232 1.758-3.214 1.758-6.21 0-5.866-1.242-10.078-1.242-10.078L18.773 3z" fill="#000"/></g><defs><clipPath id="clip0"><path fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs></svg></span>Beauty & Body Care</Link></li>
                    <li ><Link to="preforms-products" onClick={handleToggle}><span className="navdropdown__icon navdropdown__icon-3"><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 2H5a2 2 0 00-2 2v2a2 2 0 002 2v11a3 3 0 003 3h8a3 3 0 003-3V8a2 2 0 002-2V4a2 2 0 00-2-2zm-2 14h-6v-4h6v4zm0-6h-7a1 1 0 00-1 1v6a1 1 0 001 1h7v1a1 1 0 01-1 1H8a1 1 0 01-1-1V8h10v2zM5 6V4h14v2H5z" fill="#000"/></svg></span>Pet & Preforms</Link></li>
                </FadeIn>
                </ul>
            
       </div>
    )
}

export default NavDropDown
