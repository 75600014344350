import React from 'react';
import './DeoAbpotSnippet.css'

function DeoAbpotSnippet() {
    return (
        <div className="deoabout__container">
            <div className="deoabout__image"></div>
            <div className="deoabout__text">
                <h2>High Irresistible Scents.</h2>
                <p>Magnetic and alluring, Swiss Flower Deo-Body Sprays explores a new chapter in the art with irresistible fragrance. It's lingering dry down resonates to leave the wearer and those around feeling enchanted. </p>

                <p>High-quality body scents for men and women. We focus on variety and great scents. Our scents last long without any need to reapply, making them perfect for your day-to-day activities.</p>
            </div>

        </div>
    )
}

export default DeoAbpotSnippet
