import React from 'react';
// import CountryInfo from '../countryInfo/CountryInfo';
import './AboutSnippet.css';

import {Link} from 'react-router-dom'




function AboutSnippet() {
    return (
        <div className="aboutsnippet_container">
            <div className="aboutsnippet__container-bg"></div>

            <div className="aboutsnippet__contents">
                <div className="aboutsnippet__texts">
                    <h2>Spanning Accross Africa</h2>
                    <p>Our products are vastly distributed and used across West African and neighboring  countries. We strive to provide our customers the best quality products. </p>
                </div>

                <div className="aboutsnippet__info-container">
                    <div className="aboutsnippet__info-1">
                        <h3>58 products</h3>
                        <p>We boast of producing a wide range of quality products.</p>
                    </div>
                    <div className="aboutsnippet__info-1">
                        <h3>23 Countries</h3>
                        <p>We have built a network accross 23 countries globally.</p>
                    </div>
                    <div className="aboutsnippet__info-1">
                        <h3>600+ Distributors</h3>
                        <p>Increasing the accessibility of our products by active distributions.</p>
                    </div>
                </div>
                    <div className="aboutsnippet__btn-container">
                        <Link to="/about"><div className="aboutsnippet__btn">Read about us <span>  <svg width="22" height="15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M0 7.032a.781.781 0 00.781.781h18.427L14.29 12.73a.783.783 0 101.106 1.106l6.25-6.25a.78.78 0 000-1.106L15.397.23a.782.782 0 10-1.106 1.106l4.917 4.916H.78a.781.781 0 00-.78.781z" fill="#4DE9B1"/></svg></span></div></Link>
                    </div>

            </div>
            {/* <div className="aboutsnippet__map-container">
                    {countryData.map((country, i)=>{
                        return <CountryInfo key={i} name={country.name} flag = {country.flag} position = {country.position} />
                    })}          
                
            </div> */}

    {/* <CountrySlider/> */}

        </div>

    )
}

export default AboutSnippet
