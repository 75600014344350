import React from 'react';
import CareerOffers from '../../components/careerOffers/CareerOffers';
// import CareerTestimonial from '../../components/careerTestimonial/CareerTestimonial';
import Button from '../../components/Button/Button'
import './Career.css';
import {motion} from 'framer-motion';
import { pageVariants } from '../../utils/animateVariant';
// import { Link } from 'react-router-dom';

function Career() {
    return (
        <motion.div  initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}>
        <div className="career__header">
            <div className="career__header-content">
                <div className="career_header-text">
                    <h1>Career Opportunities</h1>
                    <p>Interested in joining our team to make a difference? We believe in collaborations, innovative thinking and equal participation to achieve our goals and objectives.</p>
                   <Button text='See opennings' link='/jobs'/>
                </div>
                
            </div>
            <div className="career__header-image-container">
                <div className="career__header-image">
                    <div className="career__header-image-element"></div>
                </div>
            </div>
        </div>

        <CareerOffers/>
        {/* <CareerTestimonial/> */}

        </motion.div>
    )
}

export default Career
