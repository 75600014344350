import React from 'react';
import './ProductDetailsSlider.css';
import SlickSlider from "react-slick";
import FadeIn from 'react-fade-in';
import {motion} from 'framer-motion'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import happyHome from '../../images/happy_home-1.jpg';
import bodyCare from '../../images/happy_home-2.jpg';
import Pet from '../../images/pet-preforms-1.jpg';

function ProductDetailsSlider() {
    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
        cssEase: "linear",
      };

      const slideVariant = {
          hidden:{y: 100, opacity: 0},
          visible:{
              y: 0,
              opacity: 1,
              transition:{
                  type: 'tween',
                  delay: 0,
                  duration: .6,
                  ease:'easeOut'
              }
          }
        
        }

    return (

        <SlickSlider {...settings}>
        <motion.div variants={slideVariant} initial="hidden" animate="visible" className="product__slider-container">
            <div className="product__slider">
                <div className="product__slider-image"><img src={happyHome} alt="Couples" /></div>
                <div className="product__slider-texts">
                <FadeIn>
                    <div className="product__slider-heading"><h2>Creating Happier <br/>Environments.</h2></div>
                    <div className="product__slider-paragraph"><p>Neutralizing bad odours and keeping your environment fresh and habitable. We offer a wide range of air fresheners and home care produtcs.</p></div>
                </FadeIn>
                </div>
                <div className="slider-element"></div>
            </div>
        </motion.div>

        <div className="product__slider-container">
            <div className="product__slider">
                <div className="product__slider-image"><img src={bodyCare} alt="Couples" /></div>
                <div className="product__slider-texts">
                <FadeIn>
                    <div className="product__slider-heading"><h2>Giving you the <br/> Confidence.</h2></div>
                    <div className="product__slider-paragraph"><p>Preventing bad odours, keeping you fresh and alleviating your mood all day long. We offer a wide range of personal care products  tested for quality and effectiveness.</p></div>
                </FadeIn>
                </div>
                <div className="slider-element"></div>
            </div>
        </div>

        <div className="product__slider-container">
            <div className="product__slider">
                <div className="product__slider-image"><img src={Pet} alt="Couples" /></div>
                <div className="product__slider-texts">
                <FadeIn>
                    <div className="product__slider-heading"><h2>High Quality Materials,  <br/>with state of the art technologies.</h2></div>
                    <div className="product__slider-paragraph"><p>We always work hard to do our best, with our skilled and dedicated staff with cutting edge  technology to meet and exceed your requirements and expectations.</p></div>
                </FadeIn>
                </div>
                <div className="slider-element"></div>
            </div>
        </div>

        </SlickSlider>
        
    )
}

export default ProductDetailsSlider
