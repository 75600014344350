import React from "react";
import {useLocation, Switch, Route } from "react-router-dom";


import TopBar from "./components/topbar/TopBar";
import Navbar from "./components/navbar/Navbar";

import HomePage from "./pages/homepage/HomePage";
import Footer from "./components/Footer/Footer";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Career from "./pages/career/Career";
import Jobs from "./pages/jobspage/Jobs";
import ScrollToTop from 'react-router-scroll-top';
import {AnimatePresence} from 'framer-motion';

import NotFound from './pages/notFound/NotFound';
import Homecare from "./pages/homecarenew/Homecare";
import BodyCareNew from "./pages/bodyCareNew/BodyCareNew";
import Pet from "./pages/pet/Pet";
import ProductPage from "./pages/productpage/ProductPage";

import Job from "./components/jobPage/Job";
import Media from "./pages/media/Media";


function App() {
  const location = useLocation()

  return (
    <ScrollToTop>
      <TopBar />
      <Navbar />
          <AnimatePresence location={location}>
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route path="/about" exact component={About} />
              <Route path="/home-care-products" component={Homecare} />
              <Route path="/body-care-products" component={BodyCareNew} />
              <Route path="/preforms-products" component={Pet} />
              <Route path="/contact" component={Contact} />
              <Route path="/career" component={Career} />
              <Route path="/media" component={Media} />
              <Route path="/jobs" component={Jobs} />
              <Route path="/product/:id" component={ProductPage} />
              <Route path="/job/:id" component={Job} />
              <Route path="*" component={NotFound}></Route>
            </Switch>
          </AnimatePresence>
      <Footer/>
    </ScrollToTop>

  );
}

export default App;
