import React from 'react';
import './MissonAndVision.css'

function MissonAndVision() {
    return (
        <div className="missionandvision__contaianer">

            <div className="missionandvision">
                <div className="vision__container">
                    <h2>Our vision</h2>
                    <p>To be a market leader in fast moving consumer products, home care products and body care products in Sub-Saharan and the world at large by producing good quality products of international standard.</p>
                    <div className="vision__image"></div>
                </div>
                <div className="mission__container">
                    <h2>Our mission</h2>
                    <p>To build long-term relationship with our customers in producing quality products and good customer services by pursuing business through innovation and application of advanced technology.</p>
                    <div className="mission__image"></div>
                </div>

            </div>

        </div> 
    )
}

export default MissonAndVision
