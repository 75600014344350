

import React from 'react';
import './Jobs.css';
import {Link} from 'react-router-dom';

import {motion} from 'framer-motion';
import { pageVariants } from '../../utils/animateVariant';


// import jobs from '../../data/jobs';


const Jobs = () => {


  
  return (
    
    <motion.div  initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}>
    {/* <div className="stovaJobListings">
      <div className="stova_jobsheading">
        <h2>Open Job Listings </h2>
        <p>Send CV to:  <span className='stova__emials'> recruitment@stovagroup.com</span></p>
      </div>

      <div className="stova__available-jobs">
        {jobs.map((job, i)=>{
            return <div className="stova-job" key={i}>
            <h3>{job.title} </h3>
            <p>{job.desc}</p>
          <Link to={`/job/${i}`}><button>See more details</button></Link>  
          </div>
        })}

      </div>
    </div> */}

    
 <div className="stova_jobs">
        <h1>No Job available at the moment.<br/>Please check back later.</h1>
         <Link to='/career'>Go back </Link>
  </div>
    </motion.div>



  )
}

export default Jobs



