import React from 'react';

import './CompanyInfo.css'

function CompanyInfo() {
    return (
        <div className="companyinfo__container">

            <div className="companyinfo__time">
                <h3>Business hours: 8am - 5pm</h3>
            </div>

            <div className="companyinfo__address-container">
                <div className="companyinfo__address companyinfo__address-1">
                    <h3>Head Office</h3>
                    <p>Unity Estate, KM 12 Lagos-Ibadan Expressway, Ogun State. </p>
                </div>
                <div className="companyinfo__address companyinfo__address-1">
                    <h3>Lagos branch</h3>
                    <p>17/19 Oshophey Plaza, Allen Avenue, Ikeja, Lagos. </p>
                </div>
                <div className="companyinfo__address companyinfo__address-1">
                    <h3>Email address</h3>
                    <p>info@stovagroup.com</p>
                </div>
                <div className="companyinfo__address companyinfo__address-1">
                    <h3>Phone</h3>
                    <p>+234 8059 2974 71 </p>
                </div>

            </div>

        </div>
    )
}

export default CompanyInfo
