import React from 'react';
import CompanyInfo from '../../components/companyInfo/CompanyInfo';
import ContactForm from '../../components/contactform/ContactForm';
import './Contact.css';

import {motion} from 'framer-motion';
import { pageVariants } from '../../utils/animateVariant';

function Contact() {
    return (
    <motion.div initial="initial"
    animate="in"
    exit="out"
    variants={pageVariants}>
        <div className="contactheader">
            <div className="contactheader__texts">
                <h1>Contact us</h1>
                <p>For complaints or inquiries about our products and services?</p>
            </div>
        </div>

        <div className="contactmain">
            <div className="contactmain__texts">
                <CompanyInfo/>
            </div>
            <div className="contactmain__form">
                <div className="contactmain__support">
                    <div className="contact__support-photo">
                        <div className="contact-support-indicator"></div>
                    </div>
                    <h3>Send an email to our customer support.</h3>
                </div>
                <ContactForm/>
            </div>
        </div>
    </motion.div>
    )
}

export default Contact
