import React from 'react';
import './Slider.css';
import {motion} from 'framer-motion';
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import sliderImageDesktop_1 from '../../images/Swiss-home-banner-liquid-new.jpg'
import sliderImageDishWasher from '../../images/Swiss-home-banner-dishwasher-desktop.jpg'
import sliderImageDishWasherMobile from '../../images/swiss-dish-mobile.jpg'
import sliderImageDesktop_2 from '../../images/slider-image-2.jpg'
import sliderImageDesktop_3 from '../../images/slider-image-3.jpg'

import sliderImageMobile_1 from '../../images/Swiss-home-banner-liquid-m.jpg'
import sliderImageMobile_2 from '../../images/slider-image-mobile-2.jpg'
import sliderImageMobile_3 from '../../images/slider-image-mobile-3.jpg'

function Slider() {

    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 1200,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
        cssEase: "linear"
      };

    //   function nextSlide(){
    //     slickNext()
    //   }

    return (
        <SlickSlider {...settings}>
              <div className="header__slider">
             <img src={window.innerWidth > 740?sliderImageDesktop_1: sliderImageMobile_1} alt="Slider_Image" />
             
             <div className="header__slider-content header-text-color">
                 <div className="header__slider-heading-container">
                     <motion.h1 initial={{y :50}} animate={{y :0} }   transition={{ duration: .3 }}>The New </motion.h1>
                 </div>
                 <div className="header__slider-heading-container ">
                 <motion.h1 initial={{y :50}} animate={{y :0} }   transition={{ duration: .5 }}>Swiss Flower Air </motion.h1>
                 </div>
                 <div className="header__slider-heading-container">
                 <motion.h1 initial={{y :50}} animate={{y :0} }   transition={{ duration: .5 }}> Freshener Fragrance. </motion.h1>
                 </div>


                <div className="slider_countdown"></div>
             </div>

             {/* <div className="header__slider-arrow slider-arrow-left"><svg width="11" height="19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.453 17.16l-8.04-8.04 8.04-8.04" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg></div>
             <div className="header__slider-arrow slider-arrow-right"><svg width="11" height="19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.1 1.08l8.04 8.04-8.04 8.04" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg></div> */}
         </div>
               <div className="header__slider">
             <img src={window.innerWidth > 740?sliderImageDishWasher: sliderImageDishWasherMobile} alt="Slider_Image" />
             
             <div className="header__slider-content header-text-color">
                 <div className="header__slider-heading-container">
                     <motion.h1 initial={{y :50}} animate={{y :0} }   transition={{ duration: .3 }}>Introducing </motion.h1>
                 </div>
                 <div className="header__slider-heading-container">
                     <motion.h1 initial={{y :50}} animate={{y :0} }   transition={{ duration: .3 }}>Swiss Flower Dish </motion.h1>
                 </div>
                 <div className="header__slider-heading-container ">
                 <motion.h1 initial={{y :50}} animate={{y :0} }   transition={{ duration: .5 }}> Washing Liquid. </motion.h1>
                 </div>

                <div className="slider_countdown"></div>
             </div>

             {/* <div className="header__slider-arrow slider-arrow-left"><svg width="11" height="19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.453 17.16l-8.04-8.04 8.04-8.04" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg></div>
             <div className="header__slider-arrow slider-arrow-right"><svg width="11" height="19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.1 1.08l8.04 8.04-8.04 8.04" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg></div> */}
         </div>
       
      
         <div className="header__slider">
             <img src={window.innerWidth > 740?sliderImageDesktop_2: sliderImageMobile_2} alt="Slider_Image" />
             
             <div className="header__slider-content">
                 <div className="header__slider-heading-container">
                     <motion.h1 initial={{y :50}} animate={{y :0} }   transition={{ duration: .3 }}>Manufacturers of</motion.h1>
                 </div>
                 <div className="header__slider-heading-container">
                 <motion.h1 initial={{y :50}} animate={{y :0} }   transition={{ duration: .5 }}>Quality Body Care</motion.h1>
                 </div>
                 <div className="header__slider-heading-container">
                 <motion.h1 initial={{y :50}} animate={{y :0} }   transition={{ duration: .5 }}>Products. </motion.h1>
                 </div>


                <div className="slider_countdown"></div>
             </div>
{/* 
             <div className="header__slider-arrow slider-arrow-left"><svg width="11" height="19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.453 17.16l-8.04-8.04 8.04-8.04" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg></div>
             <div className="header__slider-arrow slider-arrow-right"><svg width="11" height="19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.1 1.08l8.04 8.04-8.04 8.04" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg></div> */}
         </div>
         <div className="header__slider">
             <img src={window.innerWidth > 740?sliderImageDesktop_3: sliderImageMobile_3} alt="Slider_Image" />
             
             <div className="header__slider-content">
                 <div className="header__slider-heading-container">
                     <motion.h1 initial={{y :50}} animate={{y :0} }   transition={{ duration: .3 }}>Manufacturers of</motion.h1>
                 </div>
                 <div className="header__slider-heading-container">
                 <motion.h1 initial={{y :50}} animate={{y :0} }   transition={{ duration: .5 }}>Quality PET Preforms</motion.h1>
                 </div>
                 <div className="header__slider-heading-container">
                 <motion.h1 initial={{y :50}} animate={{y :0} }   transition={{ duration: .5 }}>& Closures. </motion.h1>
                 </div>


                <div className="slider_countdown"></div>
             </div>
{/* 
             <div className="header__slider-arrow slider-arrow-left"><svg width="11" height="19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.453 17.16l-8.04-8.04 8.04-8.04" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg></div>
             <div className="header__slider-arrow slider-arrow-right"><svg width="11" height="19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.1 1.08l8.04 8.04-8.04 8.04" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg></div> */}
         </div>
         </SlickSlider>
    )
}

export default Slider
