import React from 'react';
import './ProductSnippet.css'

import {Link} from 'react-router-dom'
import {motion} from 'framer-motion';
import { pageVariants } from '../../utils/animateVariant';


function ProductSnippet({product}) {

    // const [name, desc, image, buyurl, position] = product;

    
    return (
        <motion.div initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants} className="productsnippet__container">

            <div className={` productsnippet productsnippet-${product.position}`}>
                <div className="productsnippet__content">
                    <h2>{product.name}</h2>
                    <p>{product.desc}</p>
                    <div className="productsnippet__button">
                        <Link to ={`product/${product.idname}`} className="productsnippet__button-details">See more info</Link>
                        <a href ='https://www.konga.com/search?search=STOVA%20INDUSTRIES%20LTD' target='_blank' className="productsnippet__button-buy" rel="noreferrer">Buy online</a>
                        
                    </div>
                </div>

                <div className="productsnippet__image">
                    <img src={product.image} alt="Liquid" />
                </div>
            </div>

        </motion.div>
    )
}

export default ProductSnippet
