import React from 'react'
import './OurStory.css'

function OurStory() {
    return (
        <div className="ourstory__container">
            <div className="ourstory">
                <div className="oustory__texts-container">
                    <div className="ourstory__texts">
                        <h2>Our story in numbers</h2>
                        <p>We have a profile of leading innovation in home and personal care products. We visualize global expansion by generously investing in human capital for a higher capacity drive that trigger assets increase for optimal performance and developmental support. </p>
                    </div>
                    
                </div>
                <div className="ourstory__cards-container">
                <div className="ourstory__cards">
                    <div className="ourstory__card ourstory__card-1">
                        <h3>2005</h3>
                        <p>Founded in Lagos Nigeria.</p>
                    </div>
                    <div className="ourstory__card ourstory__card-1">
                        <h3>2012</h3>
                        <p>Incorporated as a Limited Liability Company.</p>
                    </div>
                    <div className="ourstory__card ourstory__card-1">
                        <h3>16</h3>
                        <p>Years active in business & operations.</p>
                    </div>
                    <div className="ourstory__card ourstory__card-1">
                        <h3>58</h3>
                        <p>Products manufactured over the years.</p>
                    </div>
                    <div className="ourstory__card ourstory__card-1">
                        <h3>600+</h3>
                        <p>Number of distributors worldwide</p>
                    </div>
                    <div className="ourstory__card ourstory__card-1">
                        <h3>23</h3>
                        <p>Operating in 23 countries accross Africa.</p>
                    </div>
                    
                </div>
                </div>
            </div>

        </div>
    )
}

export default OurStory
