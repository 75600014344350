import React from 'react';
import './AboutHeader.css'

function AboutHeader() {
    return (
        <div className="aboutheader__container">
            <div className="aboutheader">
                <h1>STOVA INDUSTRIES LIMITED</h1>
                <p> We are proud to be among the foremost home care product manufacturing companies in Nigeria and have been in operation for over 15 years. We have designed variety of products that enables wide choices that appeal to everyone, driven by innovation and creativity.</p>
            </div>

        </div>
    )
}

export default AboutHeader
