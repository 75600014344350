import React from 'react';
import './Whyus.css'

function Whyus() {
    return (
        <div>
            <div className="whyus__container">

                <div className="whyus__card">
                    <div className="whyus__card-icon">
                        <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.8854 0.581055C8.51864 0.581055 0.898193 8.05772 0.898193 17.2477C0.898193 26.4377 8.51864 33.9144 17.8854 33.9144C27.2521 33.9144 34.8726 26.4377 34.8726 17.2477C34.8726 8.05772 27.2521 0.581055 17.8854 0.581055ZM17.8854 30.5811C10.3923 30.5811 4.29563 24.5994 4.29563 17.2477C4.29563 9.89606 10.3923 3.91439 17.8854 3.91439C25.3784 3.91439 31.4751 9.89606 31.4751 17.2477C31.4751 24.5994 25.3784 30.5811 17.8854 30.5811Z" fill="#76B3FF"/>
                            <path d="M19.5841 8.91431H16.1866V17.9376L21.7805 23.426L24.1825 21.0693L19.5841 16.5576V8.91431Z" fill="#76B3FF"/>
                        </svg>
                    </div>
                    <div className="whyus__card-text">
                        <h3>Long lasting</h3>
                        <p>Well manufactured with the very best of ingredients to have a longer lasting life span.</p>
                    </div>
                </div>
                <div className="whyus__card">
                    <div className="whyus__card-icon">
                        <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.6213 31.0179C21.6213 31.0179 12.169 31.7668 7.07931 25.7761C1.98962 19.7855 1.98962 1.81353 1.98962 1.81353C1.98962 1.81353 19.44 1.06469 25.2568 5.55768C31.0736 10.0507 29.6194 22.7808 29.6194 22.7808" stroke="#FF9485" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M31.0736 31.7668C31.0736 31.7668 22.9446 25.44 17.9858 19.7856C13.027 14.1304 10.7148 8.5531 10.7148 8.5531" stroke="#FF9485" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17.9858 19.7854L18.7129 10.0505" stroke="#FF9485" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17.9858 19.7855L10.7148 19.0366" stroke="#FF9485" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                    </div>
                    <div className="whyus__card-text">
                        <h3>Quality Fragrance</h3>
                        <p>Swiss flower air fresheners are well refined to excellence, meeting all standards.</p>
                    </div>
                </div>
                <div className="whyus__card">
                    <div className="whyus__card-icon">
                        <svg width="41" height="35" viewBox="0 0 41 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M38.2173 19.894V26.3226C38.2173 29.1083 31.6965 32.7511 23.6527 32.7511C15.6088 32.7511 9.08801 29.1083 9.08801 26.3226V20.9655" stroke="#6EDEB0" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M9.69971 21.5203C11.4995 23.9803 17.0653 26.2882 23.6526 26.2882C31.6965 26.2882 38.2173 22.8467 38.2173 19.8896C38.2173 18.2289 36.1637 16.4096 32.9407 15.1324" stroke="#6EDEB0" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M31.9752 9.17969V15.6083C31.9752 18.394 25.4544 22.0369 17.4106 22.0369C9.36675 22.0369 2.84595 18.394 2.84595 15.6083V9.17969" stroke="#6EDEB0" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4106 15.574C25.4544 15.574 31.9752 12.1326 31.9752 9.17541C31.9752 6.21611 25.4544 2.7511 17.4106 2.7511C9.36675 2.7511 2.84595 6.21611 2.84595 9.17541C2.84595 12.1326 9.36675 15.574 17.4106 15.574Z" stroke="#6EDEB0" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                    </div>
                    <div className="whyus__card-text">
                        <h3>Affordability</h3>
                        <p>Save more. A competitive pricing designed to meet all demand and budgets.</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Whyus
