import React from 'react';
import Slider from '../slider/Slider';
import './Header.css';

function Header() {
    return (
        <header className="header">

            <Slider/>

        </header>
    )
}

export default Header
