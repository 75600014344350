

import React from 'react';
import './Media.css';
// import {Link} from 'react-router-dom';

import {motion} from 'framer-motion';
import { pageVariants } from '../../utils/animateVariant';


// import jobs from '../../data/jobs';


const Media = () => {


  
  return (
    
    <motion.div  initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}>
    {/* <div className="stovaJobListings">
      <div className="stova_jobsheading">
        <h2>Open Job Listings </h2>
        <p>Send CV to:  <span className='stova__emials'> recruitment@stovagroup.com</span></p>
      </div>

      <div className="stova__available-jobs">
        {jobs.map((job, i)=>{
            return <div className="stova-job" key={i}>
            <h3>{job.title} </h3>
            <p>{job.desc}</p>
          <Link to={`/job/${i}`}><button>See more details</button></Link>  
          </div>
        })}

      </div>
    </div> */}

    
 <div className="stova_media">
        
        <div className="stova_media-header">
            <h1>Media</h1>
        </div>

        <div className="stova_media-videos">
            <div className="stova_media-video">
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/6DExnUlyxzA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>            </div>
            <div className="stova_media-video">
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/UFGxyGGCZ-A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>            </div>
            <div className="stova_media-video">
               <iframe width="100%" height="100%" src="https://www.youtube.com/embed/BMQeb9oTP_8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className="stova_media-video">
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/s_eUoUR4i7Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>            </div>
            
           
            <div className="stova_media-video">
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/ttaaNVpDHP4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>           
             </div>
           

         </div>

  </div>
    </motion.div>



  )
}

export default Media



