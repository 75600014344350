import React from 'react'
import Product from './product/Product';
import './Products.css';
import HomeCare from '../../images/Swiss-home-care-liquid-1.jpg'
import BodyCare from '../../images/product-2.jpg'
import Pet from '../../images/product-3.jpg'



const productDetails=[
    {
        title:"Home Care Products",
        image:HomeCare,
        productColor: "product__card-1",
        link: "/home-care-products",
        text:'Meet the 8-inch Chef’s Knife made from high performance German steel.'
    },
    {
        title:"Beauty & Body care",
        image:BodyCare,
        productColor: "product__card-2",
        link: "/body-care-products",
        text:'Meet the 8-inch Chef’s Knife made from high performance German steel.'

    },
    {
        title:"Pet & Closures",
        image:Pet,
        productColor: "product__card-3",
        link: "/preforms-products",
        text:'Meet the 8-inch Chef’s Knife made from high performance German steel.'

    },
]

function Products() {
    return (

        <div className="products__container">
            <div className="products">
                <div className="products__texts">
                    <div className="products__texts-heading"><h2>Explore Our Products</h2></div>
                    <div className="products__texts-paragraph"><p>With great commitment to our customers, we are focused on manufacturing Home care, Body care and Industrial products.</p></div>
                </div>

                <div className="product__cards-container">
                    {productDetails.map((product, i)=>{
                        return <Product key={i} title={product.title} image={product.image} bgColor={product.productColor} productLink = {product.link} text={product.text}/>
                    })}

                </div>

            </div>
            
        </div>
    )
}

export default Products
