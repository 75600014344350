import React from 'react';
import Header from '../../components/header/Header';
import ProductDetailsSlider from '../../components/productDetailsSlider/ProductDetailsSlider';
import Products from '../../components/products/Products';
import AboutSnippet from '../../components/aboutSnippet/AboutSnippet';
import ContactCta from '../../components/contactCta/ContactCta';

import {motion} from 'framer-motion';
import { pageVariants } from '../../utils/animateVariant';

function HomePage() {
    return (
        <motion.div initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}>
            <Header/>
            <ProductDetailsSlider/>
            <Products/>
            <AboutSnippet/>
            <ContactCta/>
        </motion.div>
    )
}

export default HomePage
