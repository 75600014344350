import React from 'react'
import AboutHeader from '../../components/aboutHeader/AboutHeader'
import CoreValues from '../../components/coreValues/CoreValues'
import MissonAndVision from '../../components/missonAndVision/MissonAndVision'
import OurStory from '../../components/ourStory/OurStory'
// import Team from '../../components/team/Team';

import {motion} from 'framer-motion';
import { pageVariants } from '../../utils/animateVariant';

function About() {
    return (
        <motion.div  initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}>
            <AboutHeader/>
            <OurStory/>
            <MissonAndVision/>
            <CoreValues/>
            {/* <Team/> */}
        </motion.div>
    )
}

export default About
