import React from 'react';
import './ProductPreview.css';


function ProductPreview({title, image}) {
    return (
        <div className="p__product">
            <div className="p__product-img"><img src={image} alt="Product" /></div>
            <div className="p__product-title">{title}</div>
        </div>
    )
}

export default ProductPreview
