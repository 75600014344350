import React from 'react';
import Cta from '../../components/cta/Cta';
import DeoAbpotSnippet from '../../components/deoAboutSnippet/DeoAbpotSnippet';
import ProductsPreview from '../../components/productsPreview/ProductsPreview';
import ScentFeatures from '../../components/scentFeatures/ScentFeatures';
import './BodyCareNew.css';
import {motion} from 'framer-motion';
import { pageVariants } from '../../utils/animateVariant';

function BodyCareNew() {
    return (
        <motion.div  initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants} className="bodycarenew__container">
            <div className="bodycarenew__header">
                <div className="bodycarenew__header-text">
                    <h1>Beauty & Body Care</h1>
                    <p>We specialize in Deodorant Body Sprays designed to improve your active lifestyle. Our focus is on creating the best and effective body care products. </p>
                </div>
                <div className="bodycarenew__header-image">
                    <div className="img"></div>
                </div>
            </div>
            <ProductsPreview/>
            <DeoAbpotSnippet/>
            <ScentFeatures/>
            <Cta/>

        </motion.div>
    )
}

export default BodyCareNew
